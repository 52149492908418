/* GLOBAL */
body {
	padding: 0;
	margin: 0;
	background-color: #704573;
	color: #fff;
}

.text-header {
	font-size: 50px;
	text-align: center;
}

h1 {
	line-height: 1.5;
	letter-spacing: 1px;
	font-family: "Londrina Solid";
	font-weight: 400;
}

h2 {
	font-family: "Londrina Solid";
	font-weight: 400;
	font-size: 28px;
}

p {
	color: #fff;
	font-family: "Source Sans Pro", "Helvetica", sans-serif;
	font-weight: 100;
}

a {
	color: #00ffff;
	text-decoration: none;
}

a:hover,
a:active {
	color: #94ffff;
}

.w3-row {
	display: flex;
	align-items: center;
}

.w3-container {
	flex: 1;
}

.center-container {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
}


.right-container{
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: flex-end;
}

.show-on-mobile{
	height:0;
	display: none;
	visibility: hidden;
}

.content-container {
	min-height: 600px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	align-items: center;
	justify-content: center;
}

.content-div {
	width: 80%;
	max-width: 900px;
	padding: 2% 0;
}

.vignette-image-mobile {
	position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
	z-index: 1;
	visibility: hidden;
	height:0;
}

.img-border{
	z-index: 2;
	overflow: hidden;
    width: 100%;
	position:absolute;
    min-height: 25px;
}

.img-border img{
	width: 100%;
	position: absolute;
}

.img-border-top, .img-border-top img{
	top: 0;
}

.img-border-bottom, .img-border-bottom img{
	bottom: 0;
}
/* END GLOBAL */

/* HEADER */
#header {
	height: 98vh;
    max-height: 1080px;
    overflow: hidden;
    position: relative;
	max-width:1920px;
}

#header-mobile {
	display: none;
	background: url("../img/header-flat.png") no-repeat
		center center/cover;
	height: 600px;
}

#header-logo-mobile {
	background-image: url("../img/header-logo.png");
	background-position: center center;
	height: 100%;
	display: block;
	background-repeat: no-repeat;
	background-size:85%;
}

#header .layer {
	background-position: bottom;
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 1080px;
    position: fixed;
    width: 100%;
    z-index: -1;
}

#header .layer-bg {
	background-color: #704573;
	background-image: url("../img/header-sky.png");
	background-position: top center;
}

#header .layer-1 {
	background-image: url("../img/header-hills.png");
	background-position: top center;
}

#header .layer-2 {
	background-image: url("../img/header-church.png");
	background-position: top center;
}

#header .layer-3 {
	background-image: url("../img/header-logo.png");
	background-position: top 40vh center;
	background-size: 780px;
}

#header .layer-4 {
	background-image: url("../img/header-trees.png");
	background-position: bottom 50px center;
}

#header .layer-overlay {
	background-image: url("../img/header-fog.png");
}
/* END HEADER */

/* INTRO */
#intro {
	background: linear-gradient(#704573, #764974);
}

#intro p{
	text-align: justify;
}

#img-heroes {
	width: 100%;
	padding-left: 10%;
}

#video-div{
	position: relative;
	padding-bottom: 56.25%;
	height: 100%;
	min-height:400px;
	overflow: hidden;
	max-width: 100%;
}

iframe{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
/* END INTRO */

/* CAVE VIGNETTE */
#cave-vignette {
	background: linear-gradient(#764974, #784b74);
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
	position: relative;
	height:500px;
	width:100%;
	max-width: 100%;
	overflow:hidden;
}

#cave-vignette .layer {
	background-position: center center;
	background-size: auto;
	background-repeat: no-repeat;
	width: 100%;
	position: absolute;
	z-index: 0;
	min-height:1080px;
}

#cave-vignette .layer-bg {
	background-image: url("../img/cave-5-back.png");
}

#cave-vignette .layer-1 {
	background-image: url("../img/cave-4-frame.png");
}

#cave-vignette .layer-2 {
	background-image: url("../img/cave-3-fog.png");
	background-position: top center;
}

#cave-vignette .layer-3 {
	background-image: url("../img/cave-2-characters.png");
}

#cave-vignette .layer-overlay {
	background-image: url("../img/cave-1-front.png");
}

/* END CAVE VIGNETTE */

/* INFO */
#info .w3-row .content-heading-spacer {
	height: 50px;
	display: block;
}

#info .w3-row .content-heading {
	height: 50px;
	display: block;
}

#info {
	background: linear-gradient(#784b74, #7e5075);
}
/* END INFO */

/* CROW VIGNETTE */

#crow-vignette {
	background: linear-gradient(#7e5075, #8a5571);
	position: relative;
	height:500px;
	width:100%;
	max-width: 100%;
	overflow:hidden;
}

#crow-vignette .layer {
	background-position: center center;
	background-size: auto;
	background-repeat: no-repeat;
	width: 100%;
	position: absolute;
	z-index: 0;
	min-height:1080px;
}

#crow-vignette .layer-bg {
	background-image: url("../img/crow-5-back.png");
	background-position: bottom 300px center;
}

#crow-vignette .layer-1 {
	background-image: url("../img/crow-4-villagers.png");
	background-position: bottom 360px center;
}

#crow-vignette .layer-2 {
	background-image: url("../img/crow-3-fog-b.png");
}

#crow-vignette .layer-3 {
	background-image: url("../img/crow-2-building.png");
	background-position: bottom 100px center;
}

#crow-vignette .layer-overlay {
	background-image: url("../img/crow-1-crow.png");
	background-position: top 250px right calc(50% - 600px);
}

/* END CROW VIGNETTE */

/* TEAM */

#img-swype {
	height: 100%;
	max-height: 550px;
}

#team {
	background: linear-gradient(#8a5571, #9b5d68);
}

.team-spacer {
	height: 2em;
	display: block;
}

#team .content-div{
	padding-bottom:0;
}

#team .w3-row:nth-of-type(2){
	align-items: flex-end;
}
/* END TEAM */

/* FOOTER */
#footer {
	min-height: 800px;
	overflow: hidden;
	position: relative;
}

#footer-bg{
	background: linear-gradient(#9b5d68, #a65f65);
	width: 100%;
	height:100%;
	z-index: -1;
	position: absolute;
}

#footer .layer {
	background-position: bottom center;
	background-size: auto;
	background-repeat: no-repeat;
	width: 100%;
	height: 800px;
	position: absolute;
	z-index: -1;
	bottom: 0;
}

#footer-mobile {
	display: none;
	background: url("../img/footer-flat.png") no-repeat
		center bottom/cover;
	height: 600px;
}

#footer .layer-bg {
	background-image: url("../img/footer-clouds.png");
	background-position: top center;
}

#footer .layer-1 {
	background-image: url("../img/footer-land-far.png");
	background-position: bottom -30px center;
}

#footer .layer-2 {
	background-image: url("../img/footer-land-mid.png");
}

#footer .layer-3 {
	background-image: url("../img/footer-land-front.png");
	background-position: bottom right;
}

#footer .layer-overlay {
	background-image: url("../img/footer-fog.png");
}

#footer-content{
	position: absolute;
    width: 100%;
    bottom: 0;
    font-size: 1.25em;
}

.footer-col{
	max-width:300px;
	width:300px;
	min-width:300px;
}

#footer #footer-content{
	z-index: 1;
    justify-content: flex-start;
}

#footer-content .content-div{
	padding: 0;
	position: absolute;
    bottom: 270px;
}

#footer-logo{
	max-width: 100%;
}

.footer-spacer{
	height: 6em;
	display: block;
}

#contact-form input, #contact-form textarea, #contact-form-mobile input, #contact-form-mobile textarea{
	/*  background-color: #272b4f; */
	/*  color: #fff; */
	 border: none !important;
	 /* border-bottom: 1px solid #fff !important; */
	 border-radius: 8px;
	 /* padding: 0; */
	 height: 100%;
	 resize: none;
	 font-size: .9em;
 }
 
 .contact-left-col{
	 display: flex;
	 flex-direction: column;
	 flex-wrap: nowrap;
	 align-content: flex-start;
	 align-items: stretch;
	 justify-content: flex-start;
 }
 
 .contact-group{
	 display: flex;
	 flex-direction: row;
	 flex-wrap: nowrap;
	 align-content: center;
	 justify-content: flex-start;
	 align-items: center;
 }
 
 .contact-input-div{
	 flex-grow: 1;
	 padding: 8px;
 }
 
 .contact-right-col{
	 display: flex;
	 flex-direction: column;
	 flex-wrap: nowrap;
	 align-content: center;
	 align-items: stretch;
	 justify-content: flex-start;
	 flex-grow: 1;
 }
 
 #contact-form .contact-message-div{
	 padding: 8px;
	 height: 100%;
 }
 
 #contact-form button, #contact-form-mobile button{
	 padding: 8px; 
	 border-radius: 8px;
	 min-width: 150px;
	 margin-right: 8px;
	 margin-top: 6px;
 }
 
 #contact-form-mobile .w3-row{
	 display: initial;
 }
 
 #contact-form-mobile .s12{
	 padding: 0 8px;
 }

 #contact-form textarea{
	min-height: 92px;
}
 
 #contact-form-mobile .contact-message-div{
	 height: 100%;
	 min-height: 80px;
	 padding: 8px 8px 8px 0;
 }
 
 #contact-form-mobile textarea{
	 min-height: 80px;
 }
 
 #contact-form-mobile button{
	 margin-right: 16px;
 }

 #form-overlay, #form-overlay-mobile{
	 position: absolute;
	 display:none;
	 visibility: hidden;
	 width: 106%;
	 height: 80%;
	 left: -3%;
	 top: 30%;
	 background-color: rgb(151 100 110 / 79%);
	 z-index: 5;
	 border-radius: 8px;
 }

 #form-feedback, #form-feedback-mobile{
	margin: 0;
	text-align: center;
	margin-right: 4px;
}

.recaptcha-text{
	font-size: .8em;
    text-align: left;
    margin: 0;    
	margin-top: -4px;
    margin-bottom: 4px;
}

.recaptcha-text a{
	font-size: 1em;
	color: #ffdbe2;
	text-decoration: underline;
}

.recaptcha-text a:hover{
	color: #fff;
}

.grecaptcha-badge { visibility: hidden; }
 
 /* END CONTACT PAGE */
 
 /* SPINNER */
 .form-spinner{
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%);
 }
 
 .lds-dual-ring {
	 display: inline-block;
	 width: 80px;
	 height: 80px;
   }
   .lds-dual-ring:after {
	 content: " ";
	 display: block;
	 width: 64px;
	 height: 64px;
	 margin: 8px;
	 border-radius: 50%;
	 border: 6px solid #fff;
	 border-color: #fff transparent #fff transparent;
	 -webkit-animation: lds-dual-ring 1.2s linear infinite;
			 animation: lds-dual-ring 1.2s linear infinite;
   }
   @-webkit-keyframes lds-dual-ring {
	 0% {
	   transform: rotate(0deg);
	 }
	 100% {
	   transform: rotate(360deg);
	 }
   }
   @keyframes lds-dual-ring {
	 0% {
	   transform: rotate(0deg);
	 }
	 100% {
	   transform: rotate(360deg);
	 }
   }
 /* END SPINNER */

/* END FOOTER */


@media only screen and (min-width: 1921px) {
	.layer{
		max-width: 1920px;
		margin: 0 auto;
		left: 0;
		right: 0;
	}
}


@media only screen and (max-width: 1200px) {
	.content-div {
		width: 80%;
		max-width: initial;
	}
}

@media only screen and (max-width: 1536px){	
	.content-div {
		max-width: 800px;
		padding: 1% 0;
	}

	#header{
		max-height: 800px;
	}

	#header .layer-bg {
		background-position: top -40px center;
	}

	#header .layer-1 {
		background-position: top -30px center;
	}

	#header .layer-2 {
		background-position: top -35px center;
	}

	#header .layer-3 {
		background-size: 700px;
		background-position: top 34vh center;
	}

	#header .layer-4 {
		background-position: bottom 150px center;
	}

	#info .w3-col.m4{
		width: 40%;
	}

	#info .w3-col.m8{
		width: 60%;
	}

	#img-heroes{
		padding-left: 5%;
	}

	.team-spacer{
		height:0;
	}

	#footer{
		min-height: 600px;
		height: 96vh;
	}

	#footer-content .content-div {
		bottom: 220px;
	}

	.footer-spacer{
		height: 5em;
	}
}

@media only screen and (max-width: 1366px) {
	.content-div {
		max-width: 750px;
		padding: 1% 0;
	}

	#footer{
		height:90vh;
	}

	#footer-content .content-div {
		bottom: 210px;
	}

	#footer-content .w3-col.m4{
		width: 40%;
	}

	#footer .layer-bg {
		background-position: bottom center;
	}

	#footer .layer-1 {
		background-position: bottom -20px center;
	}

	#footer .layer-2 {
		background-position: bottom -50px center;
	}

	#footer .layer-3 {
		background-position: bottom right;
	}
}

@media only screen and (max-width: 1280px) {
	#header{
		max-height: 600px;
	}

	#header .layer-bg {
		background-position: top -140px center;
	}

	#header .layer-1 {
		background-position: top -130px center;
	}

	#header .layer-2 {
		background-position: top -90px center;
	}

	#header .layer-3 {
		background-size: 600px;
		background-position: top 25% center;
	}

	#header .layer-4 {
		background-position: bottom 250px center;
	}
	
	.content-container {
		min-height: 500px;
	}

	.content-div{
		max-width:700px;
	}

	#intro-p, #team p{
		margin: .5em 0;
	}

/* 	p {
		line-height: 1.2em;
	} */

	h1 {
		margin: 4px 0;
		font-size: 2.1em;
	}

	#team h2:first-of-type{
		margin-top:0;
	}



	#img-swype{
		max-height:470px;
	}

	h2 {
		font-size: 1.7em;
		margin: 0;
	}

	#footer{
		min-height:500px;
		height:90vh;
	}

	#footer-content .content-div {
		bottom: 190px;
	}

	.footer-spacer{
		height: 4em;
	}

	#footer .layer-bg {
		background-position: bottom center;
	}

	#footer .layer-1 {
		background-position: bottom -130px center;
	}

	#footer .layer-2 {
		background-position: bottom -100px center;
	}

	#footer .layer-3 {
		background-position: bottom -100px right;
	}
}

@media only screen and (max-width: 1024px){

}

@media only screen and (max-width: 1024px) and (orientation: portrait){
	#footer{
		height:90vh;
		max-height: 880px;
	}

	#footer-content .content-div{
		bottom:200px;
	}
}

/* W3 CSS medium breakpoint */
@media only screen and (max-width: 992px) {
	.content-div {
		width: 90%;
		max-width: 700px;
	}

 	#footer{
		height:90vh;
		max-height:none;
		min-height: 500px;
	}

	
	#team .w3-col.m5{
		width:50%;
	}

	#team p{
		padding-right: 2%;
	}

	#team .w3-col.m7{
		margin:0;
		width:50%;
		overflow:hidden;
	}


}

@media only screen and (max-width: 992px) and (max-height: 500px){
	#header, #footer, #cave-vignette .layer, #crow-vignette .layer{
		display: none;
		height: 0;
		min-height: 0;
	}

	#cave-vignette, #crow-vignette{
		height: auto;
	}

	#header-mobile, #footer-mobile {
		display: block;
		height:99vh;
		position: relative;
	}
	
	#header-logo-mobile{
		background-size:75%;
	}

 	.vignette-image-mobile{
		width: 100%;
		height: 99vh;
		overflow: hidden;
		display: flex;
		visibility: visible;
		position: relative;
	}

	.vignette-image-mobile img{
		width:100%;
		height: auto;
	}

	h1 {
		font-size: 1.7em;
	}

	h2 {
		font-size: 1.4em;
		margin: 10px 0;
	}

	p{
		font-size: 1.1em;
	}

	#info .w3-row {
		display: block;
	}

	#info .w3-container {
		flex: initial;
	}

	#info .w3-col.m8 {
		width: initial;
	}

	#info .w3-col.m4,
	.w3-third, #footer-content .w3-col.m4 {
		width: 100%;
	}

	#info .w3-col.m4.center-container {
		width: 100%;
	}

	.content-div{
		max-width: initial;
		width:72%;
		padding: 4% 0;
	}

	.content-container{
		min-height: initial;
	}

	#video-div{
		min-height: 300px;
	}

	#img-heroes {
		padding-left: 0;
		padding-top: 4%;
		width: 80%;
	}

	#team .w3-col.m5{
		width: 60%;
	}

	#team .w3-col.m7{
		width:40%;
	}

	#team .w3-row:nth-of-type(2){
		align-items: flex-end;
	}

	#img-swype{
		padding:0;
		max-width: 100%;
		margin-right: -60px;
	}

	#footer-mobile{
		min-height: 500px;
	}

	.footer-col {
		max-width: 300px;
		width: 300px;
		min-width: 300px;
	}

	.footer-col p{
		font-size: 1em;
	}

	#footer-content{
		justify-content: flex-end;
	}

	#footer-content .content-div {
		margin-top: 0;
		bottom: 30px;
	}

}

@media only screen and (max-width: 992px) and (orientation: portrait) {
	#footer{
		height:80vh;
		max-height: 700px;
	}

	#footer-content .content-div{
		bottom:auto;
		position: relative;
		margin-top: 300px;
	}
}

/* Mobile devices START HERE - Parallax disabled. */
@media only screen and (max-width: 768px) {
	#header, #footer, #cave-vignette .layer, #crow-vignette .layer{
		display: none;
		height: 0;
		min-height: 0;
	}

	#header-mobile, #footer-mobile {
		display: block;
		height:700px;
		position: relative;
	}

	
	#header-logo-mobile{
		background-size:75%;
	}

 	.vignette-image-mobile{
		width: 100%;
		height: 600px;
		overflow: hidden;
		display: flex;
		visibility: visible;
		position: relative;
	}

	.vignette-image-mobile img{
		height: 115%;
		width:auto;
	}

	h1 {
		font-size: 1.7em;
	}

	h2 {
		font-size: 1.4em;
		margin: 10px 0;
	}

	p{
		font-size: 1.1em;
	}

	#info .w3-row {
		display: block;
	}

	#info .w3-container {
		flex: initial;
	}

	#info .w3-col.m8 {
		width: initial;
	}

	#info .w3-col.m4,
	.w3-third, #footer-content .w3-col.m4 {
		width: 100%;
	}

	#info .w3-col.m4.center-container {
		width: 100%;
	}

	.content-div{
		max-width: initial;
		width:94%;
		padding: 4% 0;
	}

	.recaptcha-text{
		padding: 0 8px;
	}

	#form-overlay-mobile {
		height: 74%;
	}


	#footer{
		min-height: 700px;
	}

	#video-div{
		min-height: 300px;
	}

	#img-heroes {
		padding-left: 0;
		padding-top: 4%;
		width: 80%;
	}

	#team .w3-row:nth-of-type(2){
		align-items: flex-end;
	}

	.right-container{
		justify-content: flex-start;
	}

	#team .w3-col.m5{
		width:75%;
	}

	#team p{
		padding-right: 2%;
	}

	#team .w3-col.m7{
		margin:0;
		width:25%;
		overflow:hidden;
	}

	#img-swype{
		max-height: 360px;
		max-width: initial;
		margin-bottom:3px;
	}

	.footer-col {
		max-width: 300px;
		width: 300px;
		min-width: 300px;
	}

	.footer-col p{
		font-size: 1em;
	}

	#footer-content{
		justify-content: flex-end;
	}

	#footer-content .content-div {
		margin-top: 0;
		bottom: 45px;
	}

}

@media only screen and (max-width: 768px) and (orientation: landscape){
	#header, #footer, #cave-vignette .layer, #crow-vignette .layer{
		display: none;
		height: 0;
		min-height: 0;
	}

	#cave-vignette, #crow-vignette{
		height: auto;
	}

	#header-mobile, #footer-mobile {
		display: block;
		height:99vh;
		position: relative;
	}
	
	#header-logo-mobile{
		background-size:75%;
	}

 	.vignette-image-mobile{
		width: 100%;
		height: 99vh;
		overflow: hidden;
		display: flex;
		visibility: visible;
		position: relative;
	}

	.vignette-image-mobile img{
		height: 100%;
    	width: auto;
	}

	h1 {
		font-size: 1.7em;
	}

	h2 {
		font-size: 1.4em;
		margin: 10px 0;
	}

	p{
		font-size: 1.1em;
	}

	#info .w3-row {
		display: block;
	}

	#info .w3-container {
		flex: initial;
	}

	#info .w3-col.m8 {
		width: initial;
	}

	#info .w3-col.m4,
	.w3-third, #footer-content .w3-col.m4 {
		width: 100%;
	}

	#info .w3-col.m4.center-container {
		width: 100%;
	}

	.content-div{
		max-width: initial;
		width:90%;
		padding: 4% 0;
	}

	.content-container{
		min-height: initial;
	}

	#video-div{
		min-height: 300px;
	}

	#img-heroes {
		padding-left: 0;
		padding-top: 4%;
		width: 80%;
	}

	.w3-row {
		display: flex;
	}

	#team .w3-col.m5{
		width: 65%;
	}

	#team .w3-col.m7{
		width:35%;
	}

	#team .w3-row:nth-of-type(2){
		align-items: flex-end;
	}

	#img-swype{
		padding:0;
		max-width: 100%;
	}

	#footer-mobile{
		min-height: 500px;
	}

	.footer-col {
		max-width: 300px;
		width: 300px;
		min-width: 300px;
	}

	#contact-form-mobile .w3-quarter{
		width:100%;
	}

	.recaptcha-text{
		padding: 0 8px;
	}

	#form-overlay-mobile {
		height: 74%;
	}

	.footer-col p{
		font-size: 1em;
	}

	#footer-content{
		justify-content: flex-end;
	}

	#footer-content .content-div {
		margin-top: 0;
		bottom: 30px;
	}

}

/* W3 CSS small breakpoint */
@media only screen and (max-width: 601px) {
	p{
		line-height: 1.1em;
	}
	.content-container{
		min-height:initial;
	}

	.w3-row {
		display: flex;
	}

	.w3-container {
		flex: initial;
	}

	.content-div {
		width: 98%;
	}

	#info .w3-row .content-heading-spacer {
		height: 0;
		display: none;
	}

	.footer-spacer{
		height: 2em;
	}

	#footer-content.content-container{
		min-height: 500px;
	}

	.content-container{
		flex: none; 
	}

	.vignette-image-mobile{
		height: 500px;
	}

	#img-heroes{
		padding-top: 10%;
		width:100%;
	}

	#team .w3-row:nth-of-type(2){
		align-items: flex-end;
	}

	#team .w3-col.m5{
		width:80%;
	}

	#team .w3-col.m7{
		margin:0;
		width:20%;
		overflow:hidden;
	}

	#img-swype{
		height: 80%;
		max-height: 360px;
		max-width: initial;
		margin-bottom:4px;
	}

	#footer-mobile{
		height: 500px;
	}

	#footer-content .content-div {
		bottom: 25px;
	}
}

@media only screen and (max-width: 601px) and (orientation:landscape) {
	.vignette-image-mobile {
		height: 100vh;
	}

	#img-heroes{
		padding-top: 4%;
    	width: 80%;
	}
}

/* Tiny screens */
@media only screen and (max-width: 420px) {
	#crow-vignette, #cave-vignette, .vignette-image-mobile{
		height: 400px;
	}
	
	h1 {
		font-size: 2em;
		line-height: 1em;
	}

	h2 {
		font-size: 1.7em;
	}

	#intro .content-div{
		width:100%;
	}

	#intro .w3-row{
		width:96%;
		margin-left:2%;
	}

	#intro p{
		text-align: left;
	}

	#intro.w3-container{
		padding: 0;
	}

	#intro .w3-row:nth-of-type(2){
		width:100%;
		margin-left:0;
	}

	#intro .w3-col{
		width: 100%;
	}

	.w3-row .w3-col.m6:first-of-type{
		padding-right:0;
	}

	.w3-row .w3-col.m6:last-of-type{
		padding-left:0;
	}

	#video-div{
		width:100%;
		min-height: 225px;
	}

	#team .w3-row{
		display: block;
	}

	#team .w3-col.m5{
		width:100%;
		display: block;
	}

	#team .w3-col.m7{
		margin:0;
		width:100%;
		justify-content: center;
	}

	#img-swype{
		height:400px;
	}

	#header-mobile, #footer-mobile{
		height: 500px;
	}

	#img-swype{
		padding-top: 10%;
	}
}

@media only screen and (max-width: 360px) {
	h1 {
		font-size: 2.1em;
		line-height: 1em;
	}

	#footer-content .content-div {
		bottom: 30px;
	}
}

@media only screen and (max-width: 320px) {
	html, body{
		overscroll-behavior-y: initial;
	}
	#video-div {
		width: 100%;
		min-height: 180px;
	}

	.footer-col {
		max-width: 100%;
		width: 100%;
		min-width: 100%;
	}


}